import { CommonModule } from '@angular/common';
import { Component, OnInit, Injectable, NgModule, ViewEncapsulation } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataFilterService } from 'src/app/services/data-filter.service';
import { GlobalService } from 'src/app/services/global.service';

export class FilterEvent {
  facilityTypes: string[];
}

declare const $: any;

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.sass'],
  encapsulation: ViewEncapsulation.None
})

@Injectable({
  providedIn: 'root'
})


export class RightSidebarComponent implements OnInit {

  // public onFilterChange: EventEmitter<any> = new EventEmitter<any>();
  filterContent: Subject<any> = new Subject<any>();
  filteringData: any = [];
  countryList: any = []
  multiCountry = new FormControl();
  multiDistributor = new FormControl();
  filterSubscription: Subscription;
  selectedCountry: any = []
  selectedDistributor: any = []
  filteredData: any = {};
  allcustomers: any[];
  DistributorsList:any = []
  isEntered: boolean = false
  constructor(private filterService: DataFilterService, private _glob: GlobalService) {
    this.filterSubscription = this.filterService.onUpdateFilter().subscribe(filter => {
      this.isEntered = false
      if (!this.isEntered) {
        this.countryList = filterService.availableCountriesList
        this.DistributorsList = filterService.getdistributorList()
        // this.getDistributors(filter.sortedArray)
        this.isEntered = true
      }
    });
  }
  // getDistributors(sortedArray: any) {
  //   console.log("POIPO", sortedArray);
  //   let DistributerList:any = []
  //   sortedArray.forEach(element => {
  //     if (element.data.distributer != undefined) {
  //       DistributerList.push(element.data.distributer.toUpperCase())
  //     }
  //   });
  //   let uniqueDistributers = DistributerList.filter((c, index) => {
  //     return DistributerList.indexOf(c) === index;
  //   });
  //   this.DistributorsList = uniqueDistributers
  //   // this.selectedDistributor = uniqueDistributers
  //   console.log("DistList",this.DistributorsList);
    
  // }

  ngOnInit() {
    this.setSkinListHeightAndScroll(true);
    this.skinChanger();
    this.changeSidebarColor();
    this.changeDarkLightTheme();
    this.setSettingListHeightAndScroll(true);
    this.checkandcallFilter()
  }
  checkandcallFilter() {
    var interval = setInterval(() => {
      if (this.isEntered) {
        // this.filterService.changeFilter(["facility"],this.filterService.getcountryList());
        this.selectedCountry = this.filterService.getcountryList()
        this.selectedDistributor = this.filterService.getdistributorList()
        console.log("IIIIIIIII",this.selectedDistributor);
        
        clearInterval(interval);
      }
    }, 10)
  }

  setSkinListHeightAndScroll(isFirstTime) {
    var height =
      $(window).height() -
      ($(".navbar").innerHeight() + $(".right-sidebar .nav-tabs").outerHeight());
    var $el = $(".right-sidebar .demo-skin");

    if (!isFirstTime) {
      $el.slimScroll({ destroy: true }).height("auto");
      $el
        .parent()
        .find(".slimScrollBar, .slimScrollRail")
        .remove();
    }

    $el.slimscroll({
      height: height + "px",
      color: "rgba(0,0,0,0.5)",
      size: "6px",
      alwaysVisible: false,
      borderRadius: "0",
      railBorderRadius: "0"
    });
  }
  skinChanger() {
    $(".right-sidebar .demo-choose-skin li").on("click", function () {
      var $body = $("body");
      var $this = $(this);

      var existTheme = $(".right-sidebar .demo-choose-skin li.actived").data(
        "theme"
      );
      $(".right-sidebar .demo-choose-skin li").removeClass("actived");
      $body.removeClass("theme-" + existTheme);
      $this.addClass("actived");

      $body.addClass("theme-" + $this.data("theme"));
      var choose_skin = "theme-" + $this.data("theme");
      localStorage.setItem("choose_skin", choose_skin);
      localStorage.setItem("choose_skin_active", $this.data("theme"));
    });
  }

  changeSidebarColor() {
    $(".rightSetting .btn-sidebar-light").on("click", function () {
      $("body").removeClass("menu_dark logo-black");
      $("body").addClass("menu_light logo-white");
      var menu_option = "menu_light";
      localStorage.setItem("choose_logoheader", "logo-white");
      localStorage.setItem("menu_option", menu_option);
    }),
      $(".rightSetting .btn-sidebar-dark").on("click", function () {
        $("body").removeClass("menu_light logo-white");
        $("body").addClass("menu_dark logo-black");
        var menu_option = "menu_dark";
        localStorage.setItem("choose_logoheader", "logo-black");
        localStorage.setItem("menu_option", menu_option);
      });
  }

  changeDarkLightTheme() {
    $(".rightSetting .btn-theme-light").on("click", function () {
      $("body").removeClass("dark submenu-closed menu_dark logo-black theme-black");
      $("body").addClass("light submenu-closed menu_light logo-white theme-white");

      $(".choose-theme li").removeClass("actived");
      $(".choose-theme li[data-theme|='white']").addClass("actived");

      var theme = "light";
      var menu_option = "menu_light";
      localStorage.setItem("choose_logoheader", "logo-white");
      localStorage.setItem("choose_skin", "theme-white");
      localStorage.setItem("theme", theme);
      localStorage.setItem("menu_option", menu_option);
    }),
      $(".rightSetting .btn-theme-dark").on("click", function () {
        $("body").removeClass("light submenu-closed menu_light logo-white theme-white");
        $("body").addClass("dark submenu-closed menu_dark logo-black theme-black");

        $(".choose-theme li").removeClass("actived");
        $(".choose-theme li[data-theme|='black']").addClass("actived");

        var theme = "dark";
        var menu_option = "menu_dark";
        localStorage.setItem("choose_logoheader", "logo-black");
        localStorage.setItem("choose_skin", "theme-black");
        localStorage.setItem("theme", theme);
        localStorage.setItem("menu_option", menu_option);
      });

  }

  setSettingListHeightAndScroll(isFirstTime) {
    var height =
      $(window).height() -
      ($(".navbar").innerHeight() + $(".right-sidebar .nav-tabs").outerHeight());
    var $el = $(".right-sidebar .demo-settings");

    if (!isFirstTime) {
      $el.slimScroll({ destroy: true }).height("auto");
      $el
        .parent()
        .find(".slimScrollBar, .slimScrollRail")
        .remove();
    }

    $el.slimscroll({
      height: height + "px",
      color: "rgba(0,0,0,0.5)",
      size: "6px",
      alwaysVisible: false,
      borderRadius: "0",
      railBorderRadius: "0"
    });
  }
  remove(arr, what) {
    var found = arr.indexOf(what);

    while (found !== -1) {
      arr.splice(found, 1);
      found = arr.indexOf(what);
    }
  }
  getFilters(nametoFilter) {

    // if (this.filteringData!=0) { 

    //   this.filteringData.forEach(element => {
    //     if (element==nametoFilter) {
    //       this.remove(this.filteringData,nametoFilter)
    //     }else{
    //       this.filteringData.push(nametoFilter)
    //       return false
    //     }
    //   });
    // }else{
    //   this.filteringData.push(nametoFilter)
    // }


    // console.log(this.filteringData,"Checking");

    // console.log('Entered',$("#facType option:selected").text().split(" "),event);
    // event = $("#facType option:selected").text().split(" ");
    // console.log('event filter ', event);
    // this.filterService.changeFilter(this.filteringData);
  }
  refresh() {
    this.filterService.allPatients = [];
    this.filterService.allCustomers = [];
    this.filterService.allTests = [];
    this._glob.avrgTests = []
    this.filter(true);
  }
  filter(isRefresh?: boolean) {
    var refresh: boolean = false;
    refresh = isRefresh
    this.filteringData = []
    if ($("#checkbox4").prop("checked")) {
      this.filteringData.push("validation");

    } else {
      this.remove(this.filteringData, "validation")

    }
    if ($("#checkbox1").prop("checked")) {
      this.filteringData.push("facility");

    } else {
      this.remove(this.filteringData, "facility")

    } if ($("#checkbox2").prop("checked")) {
      this.filteringData.push("alpha");

    } else {
      this.remove(this.filteringData, "alpha")

    } if ($("#checkbox3").prop("checked")) {
      this.filteringData.push("development");

    } else {
      this.remove(this.filteringData, "development")

    } if ($("#checkbox5").prop("checked")) {
      this.filteringData.push("marketing");

    } else {
      this.remove(this.filteringData, "marketing")

    } if ($("#checkbox6").prop("checked")) {
      this.filteringData.push("clinical trial");

    } else {
      this.remove(this.filteringData, "clinical trial")

    } if ($("#checkbox7").prop("checked")) {
      this.filteringData.push("demo");

    } else {
      this.remove(this.filteringData, "demo")

    }
    if ($("#checkbox8").prop("checked")) {
      this.filteringData.push("distributor");

    } else {
      this.remove(this.filteringData, "distributor")

    }
    this._glob.customerType =[]
    this._glob.customerType = this.filteringData;
    console.log("AAAAAAAAa",this.filteringData,this.selectedDistributor,this.selectedCountry);
    
    this.filterService.changeFilter(this.filteringData, this.selectedCountry,this.selectedDistributor, refresh);

  }
  clearAll() {
    $("#checkbox1").prop("checked", false);
    $("#checkbox2").prop("checked", false);
    $("#checkbox3").prop("checked", false);
    $("#checkbox4").prop("checked", false);
    $("#checkbox5").prop("checked", false);
    $("#checkbox6").prop("checked", false);
    $("#checkbox7").prop("checked", false);
    $("#checkbox8").prop("checked", false);
    this.selectedCountry = []
  }
  selectAll() {
    $("#checkbox1").prop("checked", true);
    $("#checkbox2").prop("checked", true);
    $("#checkbox3").prop("checked", true);
    $("#checkbox4").prop("checked", true);
    $("#checkbox5").prop("checked", true);
    $("#checkbox6").prop("checked", true);
    $("#checkbox7").prop("checked", true);
    $("#checkbox8").prop("checked", true);
    this.selectedCountry = this.filterService.getcountryList()
    this.selectedDistributor = this.filterService.getdistributorList()

  }

  setRightSidebar() {
    var _this = this;
    var $sidebar = $("#rightsidebar");
    var $overlay = $(".overlay");

    //Close sidebar
    // $(window).on("click", function (e) {
    //   var $target = $(e.target);
    //   console.log("{{{{{{{{", e,$(e.target).parent(),$target.hasClass("js-right-sidebar"));

    // if (e.target.nodeName.toLowerCase() == null) {
    // $target = $(e.target).parent();
    //   $sidebar.toggleClass("open");
    //   $overlay.fadeIn();
    // }

    //   if (!$target.hasClass("js-right-sidebar") &&_this.isOpen() && $target.parents("#rightsidebar").length === 0) {
    //     if (!$target.hasClass("bars")) $overlay.fadeOut();
    //     $sidebar.removeClass("open");
    //   }
    // });
    // $(".js-right-sidebar").on("click", function () {
    //   console.log("{{{{{{{{",_this.isOpen());
    // $sidebar.toggleClass("open");
    //     if (_this.isOpen()) {
    //       $overlay.fadeIn();
    //     } else {
    //       $overlay.fadeOut();
    //     }
    //   });
  }

  isOpen() {
    return $(".right-sidebar").hasClass("open");
  }

}
