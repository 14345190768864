import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import * as XLSX from 'xlsx';
// import * as XLSX from "sheetjs-style";
import * as XLSX_STYLE from 'xlsx-js-style';

import { Observable, Subject } from 'rxjs';
import { Position } from '@angular/compiler/public_api';
import { DefaultSettingsInterface } from '../globals/interface/default-settings.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
export enum return_data_For {
  patients = 1,
  tests = 2,
  installationDates = 3,
}
declare const $: any;

@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  selectedFacilityId = "";
  selectedRowEditData = {};
  facilityList: any = [];
  filterPatientFacility = false;
  filterTestFacility = false;
  Fresh_Test_Data = {};
  Patient_Info = {};
  navigateFromRequestTable = false;
  navigateFromAllCustomerTable = false;
  facilityNavData = {};
  allPatientFilterFacilityId: any = "";
  allPatientFilterPatientId: any = "";
  testPatientArray: any = {};
  allPatientFilter: boolean = false;
  allPatientTestFilter: boolean = false;
  customerCompleteInfoData = {};
  currentAdmin: boolean = false;
  selectedEditRowAllRequests = {};
  editAdminData: any;
  editClickedTrue: boolean = false;
  currentDate: any;
  outputArray: any = [];
  aggrResult: any = 0;
  aggrResultForfacility: any = 0;
  private facFilter = new Subject<any>();
  private facPatientsFilter = new Subject<any>();
  //Distributors

  selectedDistributorData: any;
  navigatedFromAllDistributorsTable: boolean = false;
  //Excel
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';

  // Calibration global data
  // =======================
  activeCalibrationid = "";
  selectedCalibration = 0;
  activeVerificationid = "";
  selectedVerification = 0;

  saveSignal = false;
  selectedPortindex = 0;
  SNReference1 = "0";
  SNReference2 = "0";
  SelectDevice = 0;
  SelectDeviceIndex = 0;
  // Pre Calibration global data
  // ===========================
  NumberofSamplesPoints = 1000;
  CurrentSteps = 0;
  filteredExportData: any = [];
  filteredExportFacilityWithDistributorData: any = []
  filteredExportDistributorData: any = [];
  filteredExportTestData: any = [];
  version = "05_07_2020_10_47";
  objectTopush: any = {};
  finalOutputObject: any = {};
  arraytoReturn: any = [];
  weeklyDataobj: any = {};
  finalPatientsOutputObject: any = {};
  PatientsobjectTopush: any = {};
  PatientsarraytoReturn: any = [];
  customerType: any = [];
  cuswithDistributer: any = [];
  facilityDistributerData: any = [];
  avrgTests: any = [];
  defaultSettings: DefaultSettingsInterface;
  allCustomersFilter: any = []
  currentFilter: any = [];
  constructor(private snackBar: MatSnackBar, private _http: HttpService) {
    this.getDefaultSettings;
  }


  snackbar = (colorName, text, placementFrom, placementAlign) => {
    this.snackBar.open(text, '', {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName
    });
  }


  async getDefaultSettings() {
    if (this.defaultSettings != null) return this.defaultSettings;
    return await this.fetchDefaultSettings();
  }

  fetchDefaultSettings: () => Promise<DefaultSettingsInterface> = async () => {
    return await this._http.getCollectionData("System", "defaultSettings").then((res: DefaultSettingsInterface) => {
      return new Promise<DefaultSettingsInterface>((resolve, reject) => resolve(res));
    });
  }

  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  public exportAsExcelFile(json: any[], name: string): void {
   
  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { dateNF: 'dd/mm/yyyy h:mm:ss;@', cellDates: true });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    this.currentDate = formatDate(new Date(), 'dd_MMM_yyyy', 'en');
    XLSX.writeFile(workbook, this.toExportFileName(name + this.currentDate));

  }

  public exportDistributorAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    this.currentDate = formatDate(new Date(), 'dd_MMM_yyyy', 'en');
    XLSX.writeFile(workbook, this.toExportFileName("Distributor_Report_" + this.currentDate));
  }
  public exportDistributorWithFacilityAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    this.currentDate = formatDate(new Date(), 'dd_MMM_yyyy', 'en');
    XLSX.writeFile(workbook, this.toExportFileName("Facility_Distributor_Report_" + this.currentDate));
  }
  public exportTestAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    this.currentDate = formatDate(new Date(), 'dd_MMM_yyyy', 'en');
    XLSX.writeFile(workbook, this.toExportFileName("Test_Report_" + this.currentDate));
  }
  exportDistributorAsExcel() {
    console.log("filteredExportData", this.filteredExportDistributorData);
    let Dataname: any;
    let Datavalue: any;
    let DatatoExport = [];
    let data;
    let facilityId;
    data = this.filteredExportDistributorData;
    let item = {};
    let item_test = {};
    for (var j = 0; j < data.length; j++) {
      if (data != undefined) {
        Dataname = Object.keys(data[j]);
        Datavalue = Object.values(data[j]);
        item = data[j];
        facilityId = data[j].facilityId;
        item_test = {
          "S.No": j + 1,
          "Id": data[j].id,
          "First Name": data[j].data.firstName,
          "Last Name": data[j].data.lastName,
          "Email": data[j].data.email,
          "Mes Distributor": data[j].data.mesDistributer,
          "Role": data[j].data.role,
          "Type": data[j].data.type,
        }
        DatatoExport.push(item_test);
      }
    }
    this.exportDistributorAsExcelFile(DatatoExport)
  }
  exportDistributorWithFacilityAsExcel() {
    console.log("exportDistributorWithFacilityAsExcel");
    console.log("customerType", this.customerType);
    if (this.customerType == undefined || this.customerType == "") {
      this.customerType = ['facility']
    }
    this.cuswithDistributer = [];
    this._http.getCustomersDistributors(this.customerType).then((data: any) => {
      console.log("data", data);
      console.log("data length", data.length);
      for (let i = 0; i < data.length; i++) {
        this.cuswithDistributer.push({
          facilityId: data[i].id,
          facilityName: data[i].data.facilityName,
          distributers: data[i].data.distributers
        })
      }
      console.log("cuswithDistributer", this.cuswithDistributer);
      console.log("cuswithDistribute r  length", this.cuswithDistributer.length);
      this.getDistributerData()
    })

  }
  handlingnegSigns(tmpNum, praName?: string) {

    if (tmpNum == -1) {

      return "NA";

    }

    else if (tmpNum == -2) {

      return "< 0" + "." + "2";

    }

    else if (tmpNum == -3) {

      return "< 2" + "." + "0";

    }

    else if (tmpNum == -4) {

      return "NA";

    }

    else if ((tmpNum == undefined || tmpNum == 0) && praName == 'SMI') {
      return "NA";

    }

    else if (tmpNum == -10) {

      return "< 1";

    }

    else if (tmpNum == -500) {

      return "> 500";

    } else {
      if (praName == "motility" || praName == "normal_frms" || praName == "SMI") {

        return tmpNum.toFixed()

      } else {
        return tmpNum.toFixed(1)
      }

    }
  }
  getDistributerData() {
    var excelDistributerData = [];
    for (let i = 0; i < this.cuswithDistributer.length; i++) {
      if (this.cuswithDistributer[i].distributers.length != 0 && this.cuswithDistributer[i].distributers != "NA") {
        for (let j = 0; j < this.cuswithDistributer[i].distributers.length; j++) {
          var firstName, lastName, email, role, name;
          var companyName, city, country

          if (this.cuswithDistributer[i].distributers[j].data != undefined) {
            console.log("this.cuswithDistributer[i].distributers[j].data.country", this.cuswithDistributer[i].distributers[j]);

            firstName = this.cuswithDistributer[i].distributers[j].data.firstName;
            lastName = this.cuswithDistributer[i].distributers[j].data.lastName;
            email = this.cuswithDistributer[i].distributers[j].data.email;
            role = this.cuswithDistributer[i].distributers[j].data.role;
            city = this.cuswithDistributer[i].distributers[j].data.city;
            country = this.cuswithDistributer[i].distributers[j].data.country;
            companyName = this.cuswithDistributer[i].distributers[j].data.companyName;
            console.log("first", firstName);
            var fName, lName, dName
            console.log("lastttt", lastName);


            if (firstName != undefined && lastName == undefined) {
              name = firstName
            } else if (firstName == undefined && lastName != undefined) {
              name = lastName
            } else if (firstName != undefined && firstName != undefined) {
              name = firstName + " " + lastName
            } else {
              name = "NA"
            }
          } else {
            firstName = ""
            lastName = ""
            name = "NA"
            email = "NA"
            role = "NA"
            city = "NA",
              companyName = "NA",
              country = 'NA'
          }
          console.log("name", name, this.cuswithDistributer[i].facilityId);

          excelDistributerData.push({
            facilityId: this.cuswithDistributer[i].facilityId,
            facilityName: this.cuswithDistributer[i].facilityName,
            distributerId: this.cuswithDistributer[i].distributers[j].id,
            distributerName: name,
            distributerEmail: email,
            distributerRole: role,
            companyName: companyName,
            country: country,
            city: city,
            order: this.cuswithDistributer[i].distributers[j].order,
            support: this.cuswithDistributer[i].distributers[j].support,
          })

        }
      } else {
        console.log("excelDistributerData", this.cuswithDistributer[i].distributers);
        excelDistributerData.push({
          facilityId: this.cuswithDistributer[i].facilityId,
          facilityName: this.cuswithDistributer[i].facilityName,
          distributerId: 'NA',
          distributerName: 'NA',
          distributerEmail: 'NA',
          distributerRole: 'NA',
          companyName: 'NA',
          city: 'NA',
          country: 'NA',
          order: 'NA',
          support: 'NA',
        })

      }


    }
    this.facilityDistributerData = excelDistributerData;
    this.exportFacilityAsExcel()
  }
  exportFacilityAsExcel() {
    console.log("filteredExportData", this.facilityDistributerData);
    let Dataname: any;
    let Datavalue: any;
    let DatatoExport = [];
    let data;
    let facilityId;
    var order, support;
    data = this.facilityDistributerData;
    let item = {};
    let item_test = {};
    for (var j = 0; j < data.length; j++) {
      if (data != undefined) {
        Dataname = Object.keys(data[j]);
        Datavalue = Object.values(data[j]);
        item = data[j];
        facilityId = data[j].facilityId;
        if (data[j].order == true) {
          order = "Yes"
        } else {
          order = "No"
        }
        if (data[j].support == true) {
          support = "Yes"
        } else {
          support = "No"
        }

        item_test = {
          "S.No": j + 1,
          "Facility Id": data[j].facilityId,
          "Facility Name": data[j].facilityName,
          "Distributor Id": data[j].distributerId,
          "Distributor Name": data[j].distributerName,
          "Company Name": data[j].companyName,
          "Distributor Email": data[j].distributerEmail,
          "Distributor Role": data[j].distributerRole,
          "City": data[j].city,
          "Country": data[j].country,
          "Order": order,
          "Support": support
        }
        DatatoExport.push(item_test);
      }
    }
    console.log("DatatoExport", DatatoExport);

    this.exportDistributorWithFacilityAsExcelFile(DatatoExport)
  }
  exportAsExcel() {
    console.log("filteredExportData", this.filteredExportData);
    let Dataname: any;
    let Datavalue: any;
    let DatatoExport = [];

    DatatoExport.push(
      [ "S.NO",'FACILITY INFO','' ,'',"",'','','','','' ,'',"",'','','','','','' ,'',"",'','','','','' ,'',"",'','','','','','Content Limitation', '', '', '', '','','', 'Test Mode Access', '', '', '', 
       'Credit Restriction', '', '', '', 'General Settings', '', '', '', '','','', 'Algorithm Setting', '', '', 'Result Restriction', '','', 'Video Setting','',''],
     )
     let header =  ["",
      "Facility Id",
      "Facility Name",
      "Doctor Name",
      "Email",
      "Country",
      "System Country",
      "Region",
      "Device SN",
      "Customer Type",
      "Test Credits ",
      "Installation Date  ",
      "Last Log-in  ",
      "Total # Patients  ",
      "Total Tests Count ",
      "Tests Count ",
      "Total QC Tests  ",
      "Avg. Tests/day",
      "Total Requests  ",
      "Videos #",
      "Images #",
      "No. of Service Person",
      "Phone",
      "Auto Fix",
      "Ip Address",
      "City",
      "concStd",
      "Total # Test Credits",
      "WHO Standard",
      "Driver Version",
      "Operating System",
      "Screen Resolution",
      "allowMultipleDevices",
      "englishOnly",
      "logoPath",
      "visualizationAccess",
      "HideParameters",
      "HideMorphology",
      "hideMFAcontrol",
      "ArtPrepAccess",
      "DilutedAccess",
      "FreshAccess",
      "WashedAccess",
      "countRestriction",
      "restrictedByCount",
      "restrictedByDeviceType",
      "typeRestriction",
      "saveTestSignal",
      "maxNoiseLevel",
    
      "Session Timeout",
      "Self Test on Login",
      "QC",
      "MultiFactorAuthentication",
      "debrisAssessment",
      "LQ TSC",
      "LQ MSC",
      "LV_MSC",

      "SpermMotilityIndex",
      "Velocity",
      "totalMorph",

      "numberOfAllowImages",
      "numberOfAllowVideos",
      "saveVideoLength",
     
    
  ]
  DatatoExport.push(header)
    
    let data;
    let facilityId;
    data = this.filteredExportData;
    let item = {};
    let item_test = {};
    for (var j = 0; j < data.length; j++) {
      if (data != undefined) {
        Dataname = Object.keys(data[j]);
        Datavalue = Object.values(data[j]);
        item = data[j];
        facilityId = data[j].facilityId;
       
       

    
         item_test = [
          j + 1,
          data[j].facilityId,
          data[j].facilityName,
          data[j].doctorName?? 'N.A',
          data[j].email?? 'N.A',
          data[j].country?? 'N.A',
          data[j].systemCountry?? 'N.A',
          data[j].region ?? 'N.A',
          data[j].deviceSN?? 'N.A',
          data[j].customerType?? 'N.A',
          data[j].testCredits?? 'N.A',
          data[j].creationTime?? 'N.A',
          data[j].lastSignInTime?? 'N.A',
          data[j].patientCount?? 'N.A',
          data[j].totalTestCount?? 'N.A',
          data[j].testCount?? 'N.A',
          data[j].qcTestCount?? 'N.A',
          data[j].avgTests?? 'N.A',
          data[j].requestCount?? 'N.A',
          data[j].totalVideos?? 'N.A',
          data[j].totalImages?? 'N.A',
          data[j].noSalesperson?? 'N.A',
          data[j].phone?? 'N.A',
          data[j].autoFix?? 'N.A',
          data[j].clientIP?? 'N.A',
          data[j].city?? 'N.A',
          data[j].concentrationStd?? 'N.A',
          data[j].totalCredits?? 'N.A',
          data[j].who + 2?? 'N.A',
          data[j].driverVersion ?? 'N.A',
          data[j].OperatingSystem?? 'N.A',
          (data[j].screen_width == '' || data[j].screen_height == '') ? "N.A" : data[j].screen_width + ' x ' + data[j].screen_height,
          data[j].allowMultipleDevices != undefined ? data[j].allowMultipleDevices : 'N.A',
          data[j].englishOnly != undefined ? data[j].englishOnly : 'N.A',
          data[j].logoPath != undefined ? data[j].logoPath : 'N.A',
          data[j].visualizationAccess != undefined ? data[j].visualizationAccess : 'N.A',
          data[j].hideParameters ?? 'N.A',
          data[j].hideMorphology ?? 'N.A',
          data[j].hideMFAcontrol ?? 'N.A',
          data[j].testModesAccess?.art_prep ?? 'N.A',
          data[j].testModesAccess?.diluted ?? 'N.A',
          data[j].testModesAccess?.fresh ?? 'N.A',
          data[j].testModesAccess?.washed ?? 'N.A',
          data[j].countRestriction != undefined ? data[j].countRestriction : 'N.A',
          data[j].restrictedByCount != undefined ? data[j].restrictedByCount : 'N.A',
          data[j].restrictedByDeviceType != undefined ? data[j].restrictedByDeviceType : 'N.A',
          data[j].typeRestriction != undefined ? data[j].typeRestriction : 'N.A',
          data[j].saveTestSignal != undefined ? data[j].saveTestSignal : 'N.A',
          data[j].maxNoiseLevel != undefined ? data[j].maxNoiseLevel : 'N.A',

          data[j].sessionTimeout?? 'N.A',
          data[j].selfTestOnLogin?? 'N.A',
          data[j].ignoreSignalQC ?? 'N.A',
          data[j].multiFactorAuthActive ?? 'N.A',
          data[j].debrisAssessment?? 'N.A',

          data[j].TSC ?? 'N.A',
          data[j].MSC ?? 'N.A',
          data[j].LV_MSC ?? 'N.A',
          
          data[j].spermMotilityIndex ?? 'N.A',
          data[j].velocity ?? 'N.A',
          data[j].totalMorph ?? 'N.A',

          data[j].numberOfAllowImages != undefined ? data[j].numberOfAllowImages : 'N.A',
          data[j].numberOfAllowVideos != undefined ? data[j].numberOfAllowVideos : 'N.A',
          data[j].saveVideoLength != undefined ? data[j].saveVideoLength : 'N.A',
     
      
      ];
       
          DatatoExport.push(item_test);
    
      }
    }
    let name: string = "Facility_Report_";
    this.exportAsFacilityExcelFile(DatatoExport, name)
  }
  public exportAsFacilityExcelFile(json: any[], name: string): void {
   
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
const borderStyle =
 {
  top: { style: 'thin', color: { rgb: '000000' } },
  bottom: { style: 'thin', color: { rgb: '000000' } },
  left: { style: 'thin', color: { rgb: '000000' } },
  right: { style: 'thin', color: { rgb: '000000' } }
}
   
      // Applying styles to headers
      const headerStyles = {
        
       
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: borderStyle,
      };
     
      ws['!merges'] = [
        { s: { r: 0, c:  1 }, e: { r:  0, c: 31 } },
        { s: { r:  0, c: 32 }, e: { r:  0, c: 38 } },
        { s: { r:  0, c: 39 }, e: { r:  0, c: 42 } },
        { s: { r:  0, c: 43 }, e: { r:  0, c: 46 } },
        { s: { r:  0, c: 47 }, e: { r:  0, c: 53 } },
        { s: { r:  0, c: 54 }, e: { r:  0, c: 56} },
        { s: { r:  0, c: 57 }, e: { r:  0, c: 59} },
        { s: { r:  0, c: 60 }, e: { r:  0, c: 62} },

        { s: { r:  0, c: 0 }, e: { r:  1, c: 0} },
       
      ];
  
      // // Apply header styles
      for (let i = 0; i <= 62; i++) {
        const cell = ws[XLSX.utils.encode_cell({ c: i, r: 0 })];
   cell.s = {
    font: {
      sz: 16,
      bold: true,
      color: { rgb: "000000" },
    },
          fill: {
            fgColor: { rgb: "C3E8F2" },
          },
         
        ...headerStyles};
      
      
        const cell1 = ws[XLSX.utils.encode_cell({ c: i, r: 1 })];
     cell1.s =  {
       
          fill: {
            fgColor: { rgb: "5ABAD2" },
          },
          font: {
            sz: 14,
            bold: true,
            color: { rgb: "000000" },
          },
         
        ...headerStyles};
      }
      const range = XLSX.utils.decode_range(ws['!ref']);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
          if (cell) {
            if (!cell.s) cell.s = {};
            cell.s.border = borderStyle;
          }
        }
      }

    // // Set column widths
    // let columnWidths = [];

    // // Define the number of columns
    // let numberOfColumns = 63;
    
    // Loop to set width for each column
      let wscols = json[1]
        .reduce((accumulator, currentValue: string) => {
          accumulator.push({ wch: currentValue.length + 3 });
          return accumulator;
        }, []);

   wscols[0]={wch:8}
    
    // Assign the column widths to the worksheet
    ws['!cols'] = wscols;
    // Workbook
    const wb: XLSX_STYLE.WorkBook = XLSX_STYLE.utils.book_new();
    XLSX_STYLE.utils.book_append_sheet(wb, ws, "Sheet1");
   
  this.currentDate = formatDate(new Date(), 'dd_MMM_yyyy', 'en');
  XLSX_STYLE.writeFile(wb, this.toExportFileName(name + this.currentDate));

}
  exportAsTestExcel() {
    console.log("filteredExportData", this.filteredExportTestData);
    let Dataname: any;
    let Datavalue: any;
    let DatatoExport = [];
    let data;
    let facilityId;
    data = this.filteredExportTestData;
    let item = {};
    let item_test = {};
    for (var j = 0; j < data.length; j++) {
      if (data != undefined) {
        Dataname = Object.keys(data[j]);
        Datavalue = Object.values(data[j]);
        item = data[j];
        facilityId = data[j].facilityId;
        item_test = {
          "S.No": j + 1,
          "Facility Id": data[j].facilityid,
          "Facility Name": data[j].facilityName,
          "Test Date": formatDate(data[j].testDate, 'dd-MMM-yyyy', 'en')
        }
        DatatoExport.push(item_test);
      }
    }
    this.exportTestAsExcelFile(DatatoExport)
  }
  dateStringToTimestamp(calenderstring: string) {
    // takes "dd/mm/yyyy" or "dd/mm/yyyy hh:mm" and converts to js Date object
    // console.log('date from calendar ', calenderstring);
    let datesection;
    let timesection;
    let date;
    let time;
    let formatedDate;
    try {
      datesection = calenderstring.split(' ')[0];
      timesection = calenderstring.split(' ')[1];
      date = datesection.split('/');
      time = timesection.split(':');
      formatedDate = new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0]), Number(time[0]), Number(time[1]));
    } catch (TypeError) {
      date = calenderstring.split('/');
      formatedDate = new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0]));
    }

    return formatedDate;
  }


  timestampToDateString(timestamp: Date, dateTime: boolean = true) {
    // take js Date object and returns "yyyy/mm/dd hh:mm"
    // console.log('timestamp ', timestamp);
    // let day = timestamp.getDate().toString();
    // let month = (timestamp.getMonth() + 1).toString();
    // let year = timestamp.getFullYear().toString();
    // let hours = timestamp.getHours().toString()
    // let minutes = timestamp.getMinutes().toString();

    // if (day.length < 2) { day = '0' + day };
    // if (month.length < 2) { month = '0' + month };
    // if (hours.length < 2) { hours = '0' + hours };
    // if (minutes.length < 2) { minutes = '0' + minutes };

    // let datestring = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes;
    if (!timestamp) {
      return;
    }
    let datestring;
    if (dateTime) {
      datestring = formatDate(new Date(timestamp), 'yyyy/MM/dd HH:mm', 'en')
    } else {
      datestring = formatDate(new Date(timestamp), 'yyyy/MM/dd', 'en')

    }
    // console.log('datestring ', datestring);
    return datestring;

  }
  //<----------------- Monthly data for all facilities starts here ----------------->

  filterMonthlyTests(arrayOfallTest) {
    console.log("function called", arrayOfallTest);

    var Dates: any = [];
    // arrayOfallTest.forEach(element => {
    //   Dates.push(element.dateTime);
    // });
    // this.getWeeklyData(Dates);
    return this.returnMonthlyData(arrayOfallTest, return_data_For.tests);
  }

  filterMonthlyPatients(arrayOfallPatients) {
    var Dates: any = [];
    arrayOfallPatients.forEach(element => {
      Dates.push(element.data.addedDate);
    });

    return this.returnMonthlyData(Dates, return_data_For.patients);
  }
  filterMonthlyInstallations(installationDatesforFac) {
    console.log(installationDatesforFac);
    var Dates: any = []
    installationDatesforFac.forEach(element => {
      Dates.push(element.data.creationTime);
    });
    return this.returnMonthlyData(Dates, return_data_For.installationDates)
  }

  returnMonthlyData(Dates, data_For) {
    var tableData = []
    return new Promise<any>((res, rej) => {
      const result = Object.values(Dates.reduce((r, date) => {
        const [year, month, day] = [date.toDate().getFullYear(), date.toDate().toLocaleString('default', { month: 'short' }), date.toDate().getDate()];
        const key = `${year}_${month}`;
        r[key] = r[key] || { month, year, dates: [] };
        r[key].dates.push(day);
        tableData.push({
          month: `${month}-${year}`,
          numberOfTests: r[key].dates.length,
        })
        return r;
      }, {}));
      res(result);
    })
  }

  //<-------------------- Monthly data for all facilities ends here ---------------------->

  //<-------------------- Monthly data for individual facilities starts here ------------------>
  filterMonthlyTestsForFacility(filteredCustomers) {
    // var Dates: any = []
    // var outputArray: any = []
    // if (this.arraytoReturn.length != 0) {
    //   this.arraytoReturn = []
    // }
    // for (let index = 0; index < filteredCustomers.length; index++) {
    //   const element = filteredCustomers[index];
    //   this._http.getAllTestsForFacility(filteredCustomers[index].id).then((data: any) => {
    //     data.forEach(element => {
    //       Dates.push(element.testdata.dateTime);
    //     });
    //     if (index == filteredCustomers.length - 1) {
    //       this.returnMonthlyDataForFacility(Dates, filteredCustomers[index].id, false, true);
    //     } else {
    //       this.returnMonthlyDataForFacility(Dates, filteredCustomers[index].id, false, false);
    //     }
    //     Dates = []

    //   })
    // }
  }

  filterMonthlyPatientsForFacility(filteredCustomers) {
    // var Dates: any = []
    // var outputArray: any = []
    // this.PatientsarraytoReturn = []
    // for (let index = 0; index < filteredCustomers.length; index++) {
    //   const element = filteredCustomers[index];
    //   this._http.getallPatientsForFacility(filteredCustomers[index].id).then((data: any) => {

    //     data.forEach(element => {
    //       Dates.push(element.data.addedDate);
    //     });
    //     if (index == filteredCustomers.length - 1) {
    //       this.returnMonthlyPatientDataForFacility(Dates, filteredCustomers[index].id, true)
    //     } else {
    //       this.returnMonthlyPatientDataForFacility(Dates, filteredCustomers[index].id, false)
    //     }
    //     Dates = []
    //   })
    // }

  }

  returnMonthlyDataForFacility(Dates, FacilityID, isPatients?: boolean, canReturn?: boolean) {
    this.finalOutputObject = {}
    this.aggrResultForfacility = 0
    var id = FacilityID
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var yearlyTestcount = 0;
    var year;
    Dates.sort()
    var Check: any = []
    var Key1 = "numberOfTests"
    var Key2 = "aggrTests"
    if (isPatients) {
      Key1 = "numberOfPatients"
      Key2 = "aggrPatients"
    }
    Dates.forEach(element => {
      Check.push(element.toDate())
    });
    this.outputArray = []
    let monthCountArr = new Array(12).fill(0);
    // Dates.forEach(({ date }) => monthCountArr[date.toDate().getMonth()] += 1);
    Dates.forEach((element1, index) => {
      monthCountArr[element1.toDate().getMonth()] += 1
      year = Dates[index].toDate().getFullYear();
      if (index > 0) {
        if (Dates[index].toDate().getFullYear() == Dates[index - 1].toDate().getFullYear()) {
          yearlyTestcount += 1
          this.finalOutputObject["totals-" + year] = yearlyTestcount;
        } else {
          yearlyTestcount = 0
          yearlyTestcount += 1
          this.finalOutputObject["totals-" + year] = yearlyTestcount;
        }
      } else {
        yearlyTestcount += 1
        this.finalOutputObject["totals-" + year] = yearlyTestcount;
      }
    });
    for (let index = 0; index < Dates.length; index++) {
      const element = Dates[index];
      if (index > 0) {
        if (element.toDate().getFullYear() != Dates[index - 1].toDate().getFullYear()) {
          monthCountArr.fill(0);
          Dates.forEach(element1 => {

            if (element1.toDate().getFullYear() == element.toDate().getFullYear()) {
              monthCountArr[element1.toDate().getMonth()] += 1
            }
          });
        }
        if (element.toDate().getMonth() != Dates[index - 1].toDate().getMonth()) { // if months are different Create new object and push
          this.aggrResultForfacility += monthCountArr[element.toDate().getMonth()]
          this.objectTopush = {
            month: months[element.toDate().getMonth()] + "-" + element.toDate().getFullYear(),
            [Key1]: monthCountArr[element.toDate().getMonth()],
            [Key2]: this.aggrResultForfacility,
          }
          this.outputArray.push(this.objectTopush);
        }
      } else {
        this.aggrResultForfacility += monthCountArr[element.toDate().getMonth()]
        this.objectTopush = {
          month: months[element.toDate().getMonth()] + "-" + element.toDate().getFullYear(),
          [Key1]: monthCountArr[element.toDate().getMonth()],
          [Key2]: this.aggrResultForfacility,
        }
        this.outputArray.push(this.objectTopush);
      }
    }
    this.finalOutputObject["facilityID"] = id;
    this.finalOutputObject["data"] = this.outputArray;
    this.arraytoReturn.push(this.finalOutputObject)
    if (canReturn) {
      this.facFilter.next(this.arraytoReturn);
      this.arraytoReturn = []
    }
  }
  onUpdateFilter(): Observable<any> {
    return this.facFilter.asObservable();
  }
  //<------------- Monthly data for individual facilities ends here ---------------->
  //<------------- Monthly data for individual Patients starts here ---------------->
  returnMonthlyPatientDataForFacility(Dates, FacilityID, canReturn?: boolean) {
    this.finalPatientsOutputObject = {}
    this.aggrResultForfacility = 0
    var id = FacilityID
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var yearlyTestcount = 0;
    var year;

    Dates.sort()
    var Check: any = []
    var Key1 = "numberOfPatients"
    var Key2 = "aggrPatients"
    Dates.forEach(element => {
      try {
        if (element != undefined) {
          Check.push(element.toDate())
        }
      } catch (error) {

      }
    });
    this.outputArray = []
    let monthCountArr = new Array(12).fill(0);
    // Dates.forEach(({ date }) => monthCountArr[date.toDate().getMonth()] += 1);
    try {
      Dates.forEach((element1, index) => {
        if (Dates[index] != undefined) {
          monthCountArr[element1.toDate().getMonth()] += 1
          year = Dates[index].toDate().getFullYear();
          if (index > 0) {
            if (Dates[index].toDate().getFullYear() == Dates[index - 1].toDate().getFullYear()) {
              yearlyTestcount += 1
              this.finalPatientsOutputObject["totals-" + year] = yearlyTestcount;
            } else {
              yearlyTestcount = 0
              yearlyTestcount += 1
              this.finalPatientsOutputObject["totals-" + year] = yearlyTestcount;
            }
          } else {
            yearlyTestcount += 1
            this.finalPatientsOutputObject["totals-" + year] = yearlyTestcount;
          }
        }
      });
    } catch (error) {

    }
    try {
      for (let index = 0; index < Dates.length; index++) {
        const element = Dates[index];
        if (index > 0) {
          if (element.toDate().getFullYear() != Dates[index - 1].toDate().getFullYear()) {
            monthCountArr.fill(0);
            Dates.forEach(element1 => {
              if (element1 != undefined) {
                if (element1.toDate().getFullYear() == element.toDate().getFullYear()) {
                  monthCountArr[element1.toDate().getMonth()] += 1
                }
              }
            });
          }
          if (element.toDate().getMonth() != Dates[index - 1].toDate().getMonth()) { // if months are different Create new object and push
            this.aggrResultForfacility += monthCountArr[element.toDate().getMonth()]
            this.PatientsobjectTopush = {
              month: months[element.toDate().getMonth()] + "-" + element.toDate().getFullYear(),
              [Key1]: monthCountArr[element.toDate().getMonth()],
              [Key2]: this.aggrResultForfacility,
            }
            this.outputArray.push(this.PatientsobjectTopush);
          }
        } else {
          this.aggrResultForfacility += monthCountArr[element.toDate().getMonth()]
          this.PatientsobjectTopush = {
            month: months[element.toDate().getMonth()] + "-" + element.toDate().getFullYear(),
            [Key1]: monthCountArr[element.toDate().getMonth()],
            [Key2]: this.aggrResultForfacility,
          }
          this.outputArray.push(this.PatientsobjectTopush);
        }

      }
    } catch (error) {

    }

    this.finalPatientsOutputObject["facilityID"] = id;
    this.finalPatientsOutputObject["data"] = this.outputArray;
    this.PatientsarraytoReturn.push(this.finalPatientsOutputObject)
    if (canReturn) {
      this.facPatientsFilter.next(this.PatientsarraytoReturn);
      this.PatientsarraytoReturn = []
    }
  }
  onUpdatePatientsFilter(): Observable<any> {
    return this.facPatientsFilter.asObservable();
  }
  //<------------- weekly data implementation starts here -------------->
  getWeeklyData(Dates) {
    Dates.sort()
    let weekCountArr = new Array(7).fill(0);
    var xx = new Date();
    var startDate: any;
    var endDate: any;
    var returnArray: any = []
    var x = 0
    Dates.forEach(async (element, index) => {
      // xx.setTime(element * 1000);
      // document.write(xx.getDay());
      var arrayCountOfWeek;
      // if (new Date(element.toDate()).getDay()==0) {
      //   startDate = element
      // }else if (new Date(element.toDate()).getDay()==6) {
      //   endDate = element
      //
      // }
      if (index > 0) {
        if (new Date(Dates[index - 1].toDate()).getDay() > new Date(element.toDate()).getDay()) {
          arrayCountOfWeek = weekCountArr.reduce((a, b) => a + b, 0)
          x = new Date(element.toDate()).getDay()

          endDate = await this.getEnddate(startDate)

          this.weeklyDataobj = {
            value: arrayCountOfWeek,
            week: formatDate(startDate.toDate(), 'dd/MM/yyyy', 'en') + '-' + formatDate(endDate, 'dd/MM/yyyy', 'en')
          }
          startDate = new Date(new Date(endDate).getDate() + 1)
          returnArray.push(this.weeklyDataobj)
          weekCountArr.fill(0);
          weekCountArr[new Date(element.toDate()).getDay()] += 1
        } else {
          weekCountArr[new Date(element.toDate()).getDay()] += 1
        }
      } else {
        startDate = element;
        endDate = await this.getEnddate(startDate)
        weekCountArr[new Date(element.toDate()).getDay()] += 1
      }

    });
  }
  getEnddate(currentDate) {
    var datetoReturn: any
    var x1: any = 0
    var day = new Date(inputDate).getDay()
    try {
      var inputDate: any = currentDate.toDate()

    } catch (error) {
      var inputDate: any = currentDate
    }

    for (let day = new Date(inputDate).getDay(); day < 6; day++) {
      x1++;
      datetoReturn = new Date(inputDate).setDate(new Date(inputDate).getDate() + x1)
      console.log("My log", day, inputDate, new Date(datetoReturn));
    }
    return new Date(datetoReturn);
  }
  generateAverageData(allDates) {
    return new Promise<any>((res, rej) => {

      this.avrgTests = []
      let distinctDates: any = [];
      this.avrgTests;

      const distinct = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };
      distinctDates = allDates.filter(distinct);
      distinctDates.sort();
      let days = 1;
      let test_count = 0;
      for (const date of distinctDates) {
        let daily_test_count = 0;
        for (const testDate of allDates) {
          if (testDate === date) {
            test_count++;
            daily_test_count++;
          }
        }
        let avrg = test_count / days;

        this.avrgTests.push({ Date: date, DailyTests: daily_test_count, TotalTests: test_count, Days: days, Avrg: avrg })
        days++;
      }
      res(this.avrgTests)

    })

  }
  public getPosition(): Observable<any> {
    return Observable.create(
      (observer) => {
        navigator.geolocation.watchPosition((pos: any) => {
          console.log("from glob pos", pos);

          observer.next(pos);
        }),
          () => {
            console.log('Position is not available');
          },
        {
          enableHighAccuracy: true
        };
      });
  }

  showNotification(colorName, text, placementFrom, placementAlign, animateEnter, animateExit) {
    if (colorName === null || colorName === '') { colorName = 'bg-black'; }
    if (text === null || text === '') { text = 'Turning standard Bootstrap alerts'; }
    if (animateEnter === null || animateEnter === '') { animateEnter = 'animated fadeInDown'; }
    if (animateExit === null || animateExit === '') { animateExit = 'animated fadeOutUp'; }
    var allowDismiss = true;

    $.notify({
      message: text
    },
      {
        type: colorName,
        allow_dismiss: allowDismiss,
        newest_on_top: true,
        timer: 1000,
        placement: {
          from: placementFrom,
          align: placementAlign
        },
        animate: {
          enter: animateEnter,
          exit: animateExit
        },
        template: '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' + (allowDismiss ? "p-r-35" : "") + '" role="alert">' +
          '<span data-notify="icon"></span> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }
}
