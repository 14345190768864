import { Patient } from './../globals/globals';
import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { GlobalService } from './global.service';
import { HttpService } from './http.service';
import { formatDate } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class DataFilterService {

  private facFilter = new Subject<any>();
  AllTests = new Subject<any>();
  private patFilter = new Subject<any>();
  private testFilter = new Subject<any>();
  private requestFilter = new Subject<any>();
  public orphanPatientsArr$ = new Subject<any>();
  private currentData: any = '';
  chartArray: any = [];
  filteredData: any = {};
  patientsArray: any = [];
  allCustomers: any = [];
  erroneousDevices: any = [];
  erroneousCalibrations: any = [];
  orphanPatients: any = [];

  calledonce: boolean = false;
  allPatients: any = [];
  allTests: any = [];
  filteredPatients: any = [];
  filteredTests: any[];
  patients: number = 0;
  tests: number = 0;
  typeToFilter: string[] = [];
  excelData: any = [];
  isEnteredfunc: boolean = false
  availableCountriesList: any = []
  countriesTofilter: any[];
  isloopRunningTests: boolean = false;
  isloopRunningPatients: boolean = false;
  availableDistributors: any = [];
  allPatientsTests: any;

  constructor(private httpService: HttpService, private http: HttpClient, private _glob: GlobalService, private router: Router) {
    // this.getAllTests()
    this.getAllcustomers()
    // this.getOrphanPatients()
    this.getErroneousDevices()
    this.getErroneousCalibrations()
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe(event => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          //   Object.keys(this.filteredData).forEach(function(index) {
          //     this.filteredData[index] = null
          // });
          this.filteredData = {}

        }
      })
  }

  getAllcustomers() {
    this.httpService.getallCustomers().then((customers) => {
      this.allCustomers = customers;
      console.log('ALL CUSTOMERS', this.allCustomers);
    })
  }


  getOrphanPatients() {
    this.httpService.getTotalPatients().then(patients => {
      let pSet = new Set(patients)
      console.log(pSet);
      pSet.forEach((pID) => {
        this.httpService.getCollectionData('SQACustomers', pID).then((res) => {
          if (res.data === undefined) {
            this.orphanPatients.push(pID);
          }
        })
      })
      this.orphanPatientsArr$.next(this.orphanPatients);
    })
  }

  getErroneousCalibrations() {
    this.httpService.getCalibrations().then((calibrations) => {
      calibrations.forEach((calibration) => {
        this.erroneousCalibrations.push(calibration)
      })
    })
  }


  getErroneousDevices() {
    this.httpService.getErroneousDevices().then((devices) => {
      devices.forEach(device => {
        if (device.data.usingFacility == undefined) {
          this.erroneousDevices.push(device);
        }
      });
    })
  }

  updateDataFilter(facilityId, servicePersons) {
    for (let i = 0; i < this.filteredData.sortedArray.length; i++) {
      if (this.filteredData.sortedArray[i].id == facilityId) {
        this.filteredData.sortedArray[i].data.servicePersons = servicePersons;
      } else {
      }
    }

  }

  async changeFilter(facType: string[], countries?: string[], Distributors?: string[], isRefresh?: boolean) {
    this.calledonce = true
    this.typeToFilter = [];
    this.typeToFilter = facType;
    var arraywithFacilityType: any = []
    var arraywithDistributorFilter: any = []
    this.countriesTofilter = countries
    var distributors = Distributors
    var firstFacilityOndate;
    this.chartArray = []
    var count: any = 0;
    this.patients = 0;
    this.tests = 0;
    var length = 0
    let allPromises: any = []
    var isundefined = false
    if (this.typeToFilter.length >= this.countriesTofilter.length) {
      length = this.typeToFilter.length
    } else {
      length = this.countriesTofilter.length
    }
    // var event:any = []
    for (var key in this.filteredData) {
      delete this.filteredData[key];
    }

    // if (this.allCustomers.length == 0 || isRefresh) {
    this.isEnteredfunc = true;
    this.allCustomers = []
    const facilitytoFilter = (facility) => {
      return facility.length != 0 ? facType.includes(facility.data.customerType) : true
    }
    const countrytoFilter = (facility) => {
      return countries.length != 0 ? countries.includes(facility.data.systemCountry) : true
    }

    this.httpService.getallCustomers().then(async (numbs) => {
      this.allCustomers = numbs;
      const arraywithFilter: any[] = this.allCustomers.filter(facilitytoFilter).filter(countrytoFilter)

      arraywithFilter.forEach(element => {
        this.patients += Number(element.data.patientCount)
        this.tests += Number(element.data.totalTestCount ? element.data.totalTestCount : element.data.testCount)
      });

      this.filteredData = {
        facilities: arraywithFilter.length,
        patients: this.patients,
        tests: this.tests,
        sortedArray: arraywithFilter,
      }
      this.isEnteredfunc = false;
      // this.getFilteredCustomerData(arraywithFilter);

      if (this.router.url.toString().includes('requests')) {
        this.filterRequests();
      }
      this.facFilter.next(this.filteredData);
    });
    // }
  }

  getcountryList() {
    var allCountries: any = []
    console.log(this.allCustomers);

    this.allCustomers.forEach(element => {
      if (element.data.systemCountry == null) {
        allCountries.push("No Settings")

      } else {
        allCountries.push(element.data.systemCountry)
      }
    });
    let uniqueCountries = allCountries.filter((c, index) => {
      return allCountries.indexOf(c) === index;
    });
    this.availableCountriesList = uniqueCountries
    return this.availableCountriesList
  }

  getdistributorList() {

    var allDistributors: any = []
    this.allCustomers.forEach(element => {
      if (element.data.distributer == null || element.data.distributer == undefined) {
        allDistributors.push("No Settings")
      } else {
        allDistributors.push(element.data.distributer)
      }
    });
    let uniqueDistributers = allDistributors.filter((c, index) => {
      return allDistributors.indexOf(c) === index;
    });
    this.availableDistributors = uniqueDistributers

    return uniqueDistributers
  }

  async changeFilterPatient() {
    // var canReturnPatients: boolean = false
    // this.allPatients = []
    // this.allPatients = this.chartArray
    // this.patientsArray = []
    // var countnum = 0;
    // if (!this.isloopRunningPatients) {
    //   for (let index = 0; index < this.allPatients.length; index++) {
    //     this.isloopRunningPatients = true
    //     const element = this.allPatients[index];
    //     //      console.log("ELEMENT", element);
    //     this.httpService.getallPatientsForFacility(element.id).then((data1: any) => {
    //       countnum = countnum + data1.length;
    //       this.patientsArray = this.patientsArray.concat(data1);
    //       if (index == this.allPatients.length - 1) {
    //         canReturnPatients = true
    //         this.isloopRunningPatients = false
    //       }
    //     })
    //   }
    // }
    // var interval = setInterval(() => {
    //   var check = [];
    //   // console.log(this.patientsArray.length, this.patients, "Checking");
    //   if (canReturnPatients) {
    //     // console.log(this.patientsArray.length, this.patients, "Entered");
    //     this.patFilter.next(this.patientsArray);
    //     canReturnPatients = false
    //     clearInterval(interval);
    //   }
    // }, 10)
  }

  async changeFilterTests() {
    // var canReturnTests: boolean = false
    // this.allTests = []
    // this.allTests = this.chartArray
    // var responseCount = 0

    // this.filteredTests = []
    // if (!this.isloopRunningTests) {
    //   for (let index = 0; index < 30; index++) { // this.allTests.length;
    //     this.isloopRunningTests = true
    //     const element = this.allTests[index];
    //     // this.delay(100).then(() => { here we are looping the facilities 
    //     this.httpService.getAllTestsForFacility(element.id).then((data1: any) => {

    //       responseCount++
    //       this.filteredTests.push(...data1);
    //       if (responseCount == this.allTests.length - 1) {
    //         canReturnTests = true;
    //         this.isloopRunningTests = false
    //       }
    //     })
    //     // })
    //   }
    // }
    // var interval1 = setInterval(() => {
    //   var check = [];
    //   if (canReturnTests) {
    //     this.testFilter.next(this.filteredTests);
    //     // this._glob.filterMonthlyTests(this.filteredTests);
    //     canReturnTests = false
    //     clearInterval(interval1);
    //   }
    // }, 10)
  }

  onUpdateFilter(): Observable<any> {
    return this.facFilter.asObservable();
  }

  onUpdatepatFilter(): Observable<any> {
    return this.patFilter.asObservable();
  }

  onUpdateTestFilter(): Observable<any> {
    return this.testFilter.asObservable();
  }

  onUpdateOrphanPatients(): Observable<any> {
    return this.orphanPatientsArr$.asObservable();
  }

  onUpdateRequestFilter(): Observable<any> {
    return this.requestFilter.asObservable();
  }

  getUpdatedData() {
    return this.currentData;
  }

  async getFilteredCustomerData(chartArray) {
    this.excelData = [];
    var lastSignInTimee, creationTimee;
    var browser;
    var screen_height;
    var screen_width;
    for (let i = 0; i < chartArray.length; i++) {
      try {
        creationTimee = new Date(chartArray[i].data.creationTime.seconds * 1000 + chartArray[i].data.creationTime.nanoseconds / 1000000)
      } catch {
        creationTimee = "";
      }

      try {
        lastSignInTimee = new Date(chartArray[i].data.lastSignInTime.seconds * 1000 + chartArray[i].data.lastSignInTime.nanoseconds / 1000000);
      } catch {
        lastSignInTimee = "";
      }
      try {
        browser = chartArray[i].data.deviceInfo.browser;
        screen_height = chartArray[i].data.deviceInfo.screen_height;
        screen_width = chartArray[i].data.deviceInfo.screen_width;
      } catch {
        browser = "";
        screen_height = "";
        screen_width = "";
      }
      var totSalesPerson;
      if (chartArray[i].data.servicePersons == [] || chartArray[i].data.servicePersons == null || chartArray[i].data.servicePersons == undefined) {
        totSalesPerson = 0;
      } else {
        totSalesPerson = chartArray[i].data.servicePersons.length;
      }


      this.excelData.push({
        id: chartArray[i].id,
        facilityId: chartArray[i].id,
        facilityName: chartArray[i].data.facilityName,
        country: chartArray[i].data.country,
        city: chartArray[i].data.city,
        customerType: chartArray[i].data.customerType,
        clientIP: chartArray[i].data.clientIP ? chartArray[i].data.clientIP : "N.A",
        region: chartArray[i].data.region,
        //distributor: chartArray[i].data.distributor,
        noSalesperson: totSalesPerson,
        designation: chartArray[i].data.designation,
        deviceSN: chartArray[i].data.deviceSN,
        doctorName: chartArray[i].data.doctorName,
        email: chartArray[i].data.email,
        lastSignInTime: lastSignInTimee,
        creationTime: creationTimee,
        patientCount: chartArray[i].data.patientCount,
        phone: chartArray[i].data.phone,
        streetNo: chartArray[i].data.streetNo,
        testCount: chartArray[i].data.testCount,
        totalTestCount: chartArray[i].data.totalTestCount,
        requestCount: chartArray[i].data.requestCount,
        qcTestCount: chartArray[i].data.qcTestCount,
        testCredits: chartArray[i].data.testCredits,
        zipCode: chartArray[i].data.zipCode,
        optional1: chartArray[i].data.optional1,
        optional2: chartArray[i].data.optional2,
        website: chartArray[i].data.website,
        photoURL: chartArray[i].data.photoURL,
        logoURL: chartArray[i].data.logoURL,
        concentrationStd: chartArray[i].data.testSettings?.concentrationStd,
        testOptional1: chartArray[i].data.testSettings?.testOptional1,
        testOptional2: chartArray[i].data.testSettings?.testOptional2,
        who: chartArray[i].data.testSettings?.who,
        debrisAssessment: chartArray[i].data.testSettings?.debrisAssessment,
        browser: browser,
        totalCredits: chartArray[i].data.totalCredits,
        screen_height: screen_height,
        screen_width: screen_width,
        systemCountry: chartArray[i].data.systemCountry,
        ...chartArray[i].data.creditRestriction,
        ...chartArray[i].data.contentLimitation,
        ...chartArray[i].data.lowQualityCriteria && chartArray[i].data.lowQualityCriteria,
        saveTestSignal: chartArray[i].data.saveTestSignal,
        maxNoiseLevel: chartArray[i].data.maxNoiseLevel,
        numberOfAllowImages: chartArray[i].data.numberOfAllowImages,
        numberOfAllowVideos: chartArray[i].data.numberOfAllowVideos,
        saveVideoLength: chartArray[i].data.saveVideoLength,
        totalImages: chartArray[i].data.totalImages ?? 'N.A',
        totalVideos: chartArray[i].data.totalVideos ?? 'N.A',
        avgTests: chartArray[i].data.averageTests ?? 'N.A',
        sessionTimeout: chartArray[i].data.sessionTimeout ?? 'N.A',
        selfTestOnLogin: chartArray[i].data.selfTestOnLogin ?? 'N.A',
        autoFix: chartArray[i].data.autoFix ? formatDate(chartArray[i].data.autoFix.toDate(), 'medium', 'en') : 'N.A',
        driverVersion: chartArray[i].driverVersion ?? 'N.A',
        OperatingSystem: chartArray[i].data.deviceInfo?.os_version ?? 'N.A',
        ignoreSignalQC: chartArray[i].data.ignoreSignalQC ?? 'N.A',
        multiFactorAuthActive: chartArray[i].data.multiFactorAuthActive ?? 'N.A',
        spermMotilityIndex: chartArray[i].data.resultsRestriction?.some((r: string) => r === "spermMotilityIndex")??'N.A',
        velocity: chartArray[i].data.resultsRestriction?.some((r: string) => r === "velocity" )??'N.A',
        totalMorph: chartArray[i].data.resultsRestriction?.some((r: string) => r === "totalMorph")??'N.A',
       
      },);
      // console.log(this.excelData);

      this._glob.filteredExportData = this.excelData;
      //console.log(this._glob.filteredExportData, "UUUUUUUUUUUUUUUUUUUUUUUUUU");
      // Promise.all(allPromises).then(res=>{
      //   console.log(allPromises,"99999999999999999999");

      // })
    }
    console.log(this._glob.filteredExportData, "UUUUUUUUUUUUUUUUUUUUUUUUUU");
  }

  getRegion(data: any, id: string) {
    const apiUrl = 'http://127.0.0.1:8080/getcountry';
    console.log(data, "KKKKKKKKKKKKKKKKKKKKK");

    return new Promise((res, rej) => {
      this.http.post(apiUrl, data).subscribe((response: any) => {
        console.log("-----------------", response);
        res({ region: response.state ?? response.regionName, facilityId: id })
      })
    })

  }

  async filterRequests() {
    let filteredRequests = [];
    await this.httpService.getAllRequests().then(allRequests => {

      let filteredFacIds = [];
      console.log("this.filteredData.sortedArray", this.filteredData.sortedArray);
      if (this.filteredData.sortedArray != undefined) {
        this.filteredData.sortedArray.forEach(facility => {
          filteredFacIds.push(facility.id);
        });
      }
      // console.log('requests facilityids ', filteredFacIds);
      filteredRequests = allRequests.filter(r => filteredFacIds.includes(r.data.facilityId));
    });
    this.requestFilter.next(await filteredRequests);
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  getAllTests() {
    console.log("gett all tests");

    this.httpService.getAlltests().then((result: any) => {
      this.setAllpatientsTests(result)
      this.allPatientsTests = result
    })
  }
  setAllpatientsTests(results?) {
    console.log("sett all tests");

    if (results != undefined) {
      this.allPatientsTests = results
    }
    this.AllTests.next(this.allPatientsTests)
  }

  onGetAlltestsFilter(): Observable<any> {
    return this.AllTests.asObservable();
  }

  onFilteringData(facType, countries) {
    return new Promise((res, rej) => {
      const facilitytoFilter = (facility) => {
        return facType.length != 0 ? facType.includes(facility.data.customerType) : true
      }
      const countrytoFilter = (facility) => {
        return countries.length != 0 ? countries.includes(facility.data.systemCountry) : true
      }
      this.httpService.getallCustomers().then(async (numbs) => {
        this.allCustomers = numbs;
        const arraywithFilter: any[] = this.allCustomers.filter(facilitytoFilter).filter(countrytoFilter)
        console.log(arraywithFilter);

        res(arraywithFilter)
        this.getFilteredCustomerData(arraywithFilter);

      });
    })
  }

}
