import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DynamicScriptLoaderService } from './services/dynamic-script-loader.service';

import { HttpService } from './services/http.service';
import { DataFilterService } from './services/data-filter.service';

//import { CommunicatorService } from './Services/communication.service';

import { AlgorithmService, Results, TestSetup, SAMPLETYPE, TESTTYPE, WBCConcStd, ChamberStd, MorphCriteria, LES, KeyParameters, Debris_Scan } from './services/algorithm.service'

import { CoreModule } from './core/core.module';
import { UserProfileComponent } from './user-profile/user-profile.component';

import { GoogleChartsModule } from 'angular-google-charts';
import { AgmCoreModule } from '@agm/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { LayoutModule } from './layout/layout.module';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    UserProfileComponent,
  ],
  imports: [
    BrowserModule,
    GoogleChartsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    LayoutModule,
    ReactiveFormsModule,
    CoreModule,
    HttpClientModule,
    MatExpansionModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDLga1tF1PcIrDx7vJOp0sNujC-GKlVF8s&v=3',
      libraries: ['places']
    }),
  ],
  providers: [HttpService, DataFilterService, /*CommunicatorService*/, AlgorithmService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DynamicScriptLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
